import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { Link } from "gatsby";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title></Title>
                <Text>
                    Tras l'èxit del curs del 2023 on el 92% d'aspirants aptes a l'entrevista de la
                    Policia Municipal de Terrassa van ser alumnes de iOpos Acadèmia...
                    <br />
                    <br />
                    Torna un curs encara més complet.
                    <br />
                    <br />
                    No busquis més el millor material està a iOpos.
                    <br />
                    <br />
                    Un curs imprescindible per posicionar-te entre els millors de l'oposició del
                    2024
                    <br />
                    <br />
                    Un curs viu, amb actualitzacions constants i en evolució.
                    <br />
                    <br />
                    És un campus virtual amb contingut de qualitat, molt senzill d'utilitzar i
                    superefectiu.
                    <br />
                    <br />
                    Dins del curs trobaràs:
                    <br />
                    <br />
                    - Cultura general bàsica i avançada
                    <br />
                    <br />
                    - Totes les notícies d'actualitat setmanals, test per posar-se a prova i
                    videoresum mensual
                    <br />
                    <br />
                    - Tot el Municipi exigit de les bases amb detalls
                    <br />
                    <br />
                    -Dades rellevants de Terrassa, festes, monuments
                    <br />
                    <br />
                    -Tot el temari exigit a la convocatòria
                    <br />
                    <br />
                    -Les Ordenances Municipals i resums
                    <br />
                    <br />
                    - 9 exàmens oficials per posar-vos a prova
                    <br />
                    <br />
                    -Vídeos de tot el temari
                    <br />
                    <br />
                    -VideoQuizz de Cultura general
                    <br />
                    <br />
                    I a més simulacres mensuals
                    <br />
                    <br />
                    Per només 79,99 euros d'un únic pagament
                    <br />
                    <br />
                    El curs estarà obert fins al dia de l'examen.
                    <br />
                    <br />
                    Entra ara i avança al teu ritme!
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/kX-1oRHb1Gw"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    ></iframe>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
